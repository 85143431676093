<template>
  <CRow align-horizontal="center">
    <CCol sm="4">
      <CCard>
        <div class="loader" v-if="isLoading">
          <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
        </div>

        <CCardHeader>
          {{ $t("admin.package_balances.title") }}

          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="float-right"
            :to="{ name: 'admin.package_balances.edit' }"
          >
            {{ $t("buttons.edit") }}
          </CButton>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol
              sm="12"
              v-for="(packages, restaurant) in items"
              :key="restaurant"
              class="mb-2"
            >
              <h6>{{ restaurant }}</h6>
              <CDataTable
                class="mt-1"
                :items="packages"
                :fields="fields"
                :noItemsView="{
                  noResults: $t('admin.no_results'),
                  noItems: $t('admin.no_items')
                }"
                border
                striped
                hover
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "package", label: this.$t("admin.package_balances.package") },
        { key: "amount", label: this.$t("admin.package_balances.amount") }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get("/api/admin/package-balances").then(response => {
        this.items = response.data.data;
        this.isLoading = false;
      });
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.package_balances.title")
    };
  }
};
</script>
