<template>
  <div>
    <CRow align-horizontal="center">
      <CCol sm="4">
        <CCard>
          <div class="loader" v-if="isLoading">
            <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
          </div>

          <CCardHeader>
            {{ $t("admin.package_balances.edit.title") }}

            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="float-right"
              @click="save"
            >
              {{ $t("buttons.save") }}
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CAlert
              color="danger"
              :fade="false"
              v-if="Object.entries(errors).length"
            >
              {{ errors.message }}
              <ul>
                <li v-for="(error, field) in errors.errors" :key="field">
                  {{ error[0] }}
                </li>
              </ul>
            </CAlert>
            <CRow>
              <CCol sm="6">
                <label>{{ $t("admin.package_balances.edit.mode") }}</label>
                <CTabs
                  variant="pills"
                  :active-tab="0"
                  @update:activeTab="setMode"
                >
                  <CTab title="Supply" />
                  <CTab title="Extract" />
                </CTabs>
              </CCol>
            </CRow>
            <CRow class="mt-2">
              <CCol sm="6">
                <label>{{ $t("admin.restaurant") }}</label>
                <CSelect :options="restaurants" :value.sync="restaurant" />
              </CCol>
              <CCol sm="6">
                <label>{{ $t("admin.date") }}</label>
                <v-date-picker
                  timezone="UTC"
                  v-model="date"
                  :model-config="modelConfig"
                >
                  <template #default="{ inputValue, togglePopover }">
                    <CInput
                      :value="inputValue"
                      @click="togglePopover"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>

            <CRow v-for="pkg in packages" :key="pkg.id">
              <CCol sm="12">
                <CInput
                  :label="pkg.name"
                  :value="balances.find(p => p.id === pkg.id).amount"
                  @input="setAmount(pkg, $event)"
                  type="number"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      errors: [],
      restaurants: [],
      restaurant: null,
      packages: [],
      balances: [],
      supply: true,
      date: new Date(new Date().setHours(0, 0, 0, 0)),
      modelConfig: {
        timeAdjust: "00:00:00"
      }
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      const requests = [
        this.$http.get("/api/admin/restaurants"),
        this.$http.get("/api/admin/packages")
      ];

      Promise.all(requests).then(responses => {
        this.restaurants = responses[0].data.data.map(restaurant => ({
          label: restaurant.name,
          value: restaurant.id
        }));
        this.packages = responses[1].data.data;
        this.prepareBalances();
        if (this.restaurants.length > 0) {
          this.restaurant = this.restaurants[0].value;
        }

        this.isLoading = false;
      });
    },
    prepareBalances() {
      this.balances = this.packages.map(pkg => ({
        id: pkg.id,
        amount: 0
      }));
    },
    setAmount(pkg, value) {
      const pkgIndex = this.balances.findIndex(p => p.id === pkg.id);
      this.balances[pkgIndex].amount = value === "" ? 0 : parseInt(value);
    },
    setMode(index) {
      this.supply = index === 0;
    },
    save() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .post(`/api/admin/restaurants/${this.restaurant}/package-balances`, {
          date: this.date,
          supply: this.supply,
          balances: this.balances
        })
        .then(() => this.$router.push({ name: "admin.package_balances" }))
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.package_balances.edit.title")
    };
  }
};
</script>
